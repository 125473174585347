












import Vue from 'vue'
import TemplateForm from './TemplateForm.vue'
import ITemplate from '../models/ITemplate'

export default Vue.extend({
  components: {
    TemplateForm
  },
  props: {
    value: Object as () => ITemplate
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
    title(): string {
      if (this.value) {
        const prefix = this.value.id ? 'Edit' : 'Add'
        return `${prefix} Template`
      }
      return ''
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
