




























import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({
  data() {
    return {
      templateName: '',
      templateUrl: '',
      templateFile: ''
    }
  },
  computed: {
    edit(): boolean {
      return !!this.form.id
    }
  },
  methods: {
    pickFile() {
      const input = this.$refs.templateInput as any
      input.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.templateName = files[0].name
        if (this.templateName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.templateUrl = fr.result as string
          this.form.$_file = files[0] // this is an resume file that can be sent to server...
        })
      } else {
        this.templateName = ''
        this.form.$_file = ''
        this.templateUrl = ''
      }
    }
  }
})
