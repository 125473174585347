









import Vue from 'vue'
import ITemplate from '../models/ITemplate'
import IQuery from '../models/IQuery'

export default Vue.extend({
  props: {
    template: Object as () => ITemplate
  },
  computed: {
    queries(): IQuery[] {
      return this.template?.graphQueries ?? []
    }
  }
})
